import React from "react"

import Layout from "../components/layout"
import TextBlock from "../components/text-block"
import Seo from "../components/seo"

const ImprintPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Imprint" />
    <TextBlock bg="green">
      <h1>Imprint</h1>
      <h2>Information according to § 5 TMG</h2>
      <p>
        <strong>patrickkrueger.com</strong>
        <br />
        Patrick Krüger
        <br />
        Marienstraße 7<br />
        10117 Berlin-Mitte
      </p>
      <p>
        <a href="/callback">+49 30 52001530</a> <br />
        <a href="mailto:hello@patrickkrueger.com">hello@patrickkrueger.com</a>
      </p>
      <h2>VAT identification number according to § 27a Umsatzsteuergesetz</h2>
      <p>DE190506345</p>
    </TextBlock>
  </Layout>
)

export default ImprintPage
